import './QASection.css';

const qaData = [
    {
        question: "Is it free?",
        // answer: "There is a free package available, but it has some limitations, including less time for botting."
        answer: "It will not be free forever. If you enter the Discord before official release, you can to use the bot for FREE when it is released! "
    },
    {
        question: "Will it work on my computer?",
        answer: "BotSafe integrates seamlessly with an Android emulator. The software is designed to be lightweight and efficient, ensuring smooth performance on the lowest-end computers. Yes, it will work!"
    },
    {
        question: "Why is it safer than other bots?",
        answer: "BotSafe is remarkably safe because it doesn't access the game's internal memory. Instead, our program utilises Computer Vision to visualize and understand the game. Secondly, your input is simulated from your own data, meaning that your bot is unique to other botters."
    },
    {
        question: "How do I download?",
        answer: "It is planned to be publicly released early 2025! BotSafe has been a prototype for a number of years, originally written in Java, unsatisfied with the computational performance with Java. BotSafe is being redesigned in a user-friendly application using C++ for maximum efficiency! I want to release a beautiful, user-friendly, reliable bot. So you can have a worry-free botting experience."
    }
];

const QASection = () => {



    // Style definitions
    const styles = {
        qaSection: {
            backgroundColor: '#f5f5f5',
            color: '#333',
            padding: '4rem 2rem',
        },
        qaContainer: {
            maxWidth: '1200px',
            margin: '0 auto',
        },
        qaTitle: {
            color: '#311eaf',
            fontSize: '2.5rem',
            fontWeight: '700',
            textAlign: 'center',
            marginBottom: '1rem',
        },
        qaSubtitle: {
            fontSize: '1.5rem',
            textAlign: 'center',
            marginBottom: '2rem',
        },
        qaGrid: {
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
            gap: '2rem',
        },
        qaItem: {
            backgroundColor: 'white',
            borderRadius: '8px',
            padding: '1.5rem',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        },
        qaQuestion: {
            color: '#1e40af',
            fontSize: '1.25rem',
            fontWeight: '600',
            marginBottom: '0.5rem',
        },
        qaAnswer: {
            fontSize: '1rem',
        },
        qaButton: {
            display: 'block',
            width: '200px',
            margin: '2rem auto 0',
            padding: '0.75rem 1.5rem',
            backgroundColor: '#1e40af',
            color: 'white',
            textAlign: 'center',
            border: 'none',
            borderRadius: '4px',
            fontSize: '1rem',
            fontWeight: '600',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease',
        },
        qaButtonHover: {
            backgroundColor: '#2563eb',
        },
        scrollTopButton: {
            position: 'fixed',
            bottom: '2rem',
            right: '2rem',
            backgroundColor: '#1e40af',
            color: 'white',
            border: 'none',
            borderRadius: '50%',
            width: '3rem',
            height: '3rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease',
        },
        scrollTopButtonHover: {
            backgroundColor: '#2563eb',
        }
    };

    // Render the component
    return (
        <div style={styles.qaSection}>
            <div style={styles.qaContainer}>
                <h2 style={styles.qaTitle}>Have any questions?</h2>
                <p style={styles.qaSubtitle}>
                    Don't hesitate to join the Discord!
                </p>

                <div style={styles.qaGrid}>
                    {qaData.map((item, index) => (
                        <div key={index} style={styles.qaItem}>
                            <h4 style={styles.qaQuestion}>{item.question}</h4>
                            <p style={styles.qaAnswer}>{item.answer}</p>
                        </div>
                    ))}
                </div>

                {/*<button*/}
                {/*    style={styles.qaButton}*/}
                {/*    onMouseOver={(e) => e.currentTarget.style.backgroundColor = styles.qaButtonHover.backgroundColor}*/}
                {/*    onMouseOut={(e) => e.currentTarget.style.backgroundColor = styles.qaButton.backgroundColor}*/}
                {/*>*/}
                {/*    Read more FAQs*/}
                {/*</button>*/}
            </div>


        </div>
    );
};

export default QASection;
