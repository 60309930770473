import botSafeSVG from '../assets/fresh_botsafe.svg';
import React from 'react';
import styled from 'styled-components';
import videoLowResUrl from '../assets/automation-video_360.mp4';

const SplashContainer = styled.div`
    position: relative;
    min-height: 100vh;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
`;

const VideoBackground = styled.video`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    filter: brightness(50%);
`;

const Content = styled.div`
    text-align: center;
    color: white;
    width: 90%;
    max-width: 1200px;
    z-index: 1;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const LogoContainer = styled.div`
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
    width: 25%;
    max-width: 256px;
    min-width: 100px;
`;

const LogoImage = styled.img`
    width: 100%;
    height: auto;
    display: block;
    filter: drop-shadow(0 0 10px white);
    animation: glow 3s infinite alternate;

    @keyframes glow {
        0% {
            filter: drop-shadow(0 0 2px white);
        }
        50% {
            filter: drop-shadow(0 0 6px white);
        }
        100% {
            filter: drop-shadow(0 0 2px white);
        }
    }
`;

const TextContent = styled.div`
    margin-top: 1rem;
`;

const Header = styled.h1`
    font-size: 4rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
    color: #0570fd;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
`;

const Title = styled.h2`
    font-size: 2.5rem;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 8rem;
    color: #ffffff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
`;

const Subtitle = styled.h3`
    font-size: 1.5rem;
    margin-top: 2rem;
    color: #eee;
    text-shadow: 3px 3px 3px rgba(0,0,0,0.3);
`;

const HeroDescription = styled.h2`
    font-size: 4rem;
    font-weight: 700; // Changed from 500 to 700 for bold
    color: #ffffff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    margin-top: 8rem;
    margin-bottom: 2rem;
`;

const SplashScreen = () => {
    return (
        <SplashContainer>
            <VideoBackground
                autoPlay
                loop
                muted
                playsInline
                src={videoLowResUrl}
            />
            <Content>
                <LogoContainer>
                    <LogoImage src={botSafeSVG} alt="BotSafe"/>
                </LogoContainer>
                <TextContent>
                    <Header>Bot Safe</Header>
                    <Title>The Safe OSRS Bot</Title>
                    <HeroDescription>Say Goodbye to Bans</HeroDescription>
                    {/*<Subtitle>Embrace the Future of Smart and Safe OSRS Botting with AI</Subtitle>*/}
                </TextContent>
            </Content>
        </SplashContainer>
    );
};

export default SplashScreen;
